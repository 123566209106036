<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="news-list-view" class="data-list-container">
    <feather-icon class="sm:inline-flex xl:hidden cursor-pointer" icon="MenuIcon" style="height: 20px; margin-right: 10px;" @click.stop="showSidebar"></feather-icon>
    <vs-table ref="table" :max-items="searchVar.itemsPerPage" :data="news">  <!-- multiple v-model="selected" pagination :max-items="itemsPerPage" search :data="news">-->

      <div slot="header" class="flex flex-wrap items-center flex-grow">
        <datepicker type="date" range v-model="dateRange2" lang="kr" style="width: 200px;" />
        <v-select style="width: 100px;" placeholder="기자" label="nickname" :reduce="nickname => nickname.idx" v-model="writer_s"
          :options="writer"/>
        <v-select style="width: 100px" placeholder="상태" label="name" :reduce="name => name.code" v-model="state"
          :options="states"/>
        <v-select style="width: 100px" placeholder="카테고리" label="name" :reduce="name => name.code" v-model="category1"
          :options="categories1"/>
        <v-select style="width: 150px" placeholder="시리즈" label="name" :reduce="name => name.code" v-model="series"
          :options="seriesArray"/>
        <v-select style="width: 100px" placeholder="검색옵션" label="name" :clearable="false" v-model="searchType"
          :options="[{name: '제목', code: 'title'},{name: '본문', code: 'description'},{name: '기사번호', code: 'newsId'}]"/>
        <div style="padding-top: 0;">
          <vs-input class="inputx" placeholder="기사 검색" v-model="searchText" @keyup.enter="searchNewsList(searchVar)"/>
        </div>
        <vs-button style="width: 50px;" color="primary" type="border" class="p-2 cursor-pointer" @click="searchNewsList(searchVar)">검색</vs-button>
        <div  id="data-list-search-rank" style="padding-top: 0;">
          <div class="p-2 border border-solid border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ ((currentx - 1) * searchVar.itemsPerPage) + 1 }} - {{ numOfNews >= searchVar.itemsPerPage ? (currentx * searchVar.itemsPerPage) : numOfNews }} of {{ numOfNews }}</span>
          </div>
        </div>
      </div>

      <template slot="thead">
        <vs-th>기사 id</vs-th>
        <vs-th v-if="windowWidth > 910" style="width: 45px;">후보</vs-th>
        <!-- <vs-th v-if="windowWidth > 910" style="width: 45px;">기획</vs-th> -->
        <vs-th v-if="windowWidth > 910 && (activeUserInfo.level >= 10 || activeUserInfo.permission.sns)" style="width: 45px;">FB/<br>TW</vs-th>
        <vs-th v-if="windowWidth > 910" style="width: 70px;">속보</vs-th>
        <vs-th v-else style="width: 70px;">후보/<br>
          <span v-if="(activeUserInfo.level >= 10 || activeUserInfo.permission.sns)">FB/<br>TW/<br></span>
          속보</vs-th>
        <vs-th>기사 제목</vs-th>
        <vs-th style="width: 55px;">상태</vs-th>
        <vs-th style="min-width: 60px; letter-spacing: -2px;">포털송고</vs-th>
        <vs-th style="width: 140px; display: flex;">
          <div :style="searchVar.sort == 'pass' ? 'color: blue;' : ''" @click="changeSort('pass')">송고시간<br>(작성시간)</div>
          <div :style="searchVar.sort == 'modi' ? 'color: blue;' : ''" @click="changeSort('modi')">수정시간</div>
        </vs-th>
        <vs-th style="width: 70px;">작성자</vs-th>
        <vs-th style="min-width: 60px; letter-spacing: -2px;">카테고리</vs-th>
        <vs-th>View</vs-th>
        <vs-th>포털</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td><p @click="showNews(indextr, tr.id)" v-html="eventColor(tr.event_flg, tr.id)"></p></vs-td>
          <vs-td v-if="windowWidth > 910"><md-checkbox v-model="isHuboNews[indextr]" style="align: center"/></vs-td>
          <!-- <vs-td v-if="windowWidth > 910"><md-checkbox v-model="isProjectNews[indextr]" @change="setProjectNews(isProjectNews[indextr], tr.id)" style="align: center"/></vs-td> -->
          <vs-td v-if="windowWidth > 910 && (activeUserInfo.level >= 10 || activeUserInfo.permission.sns)">
            <md-checkbox v-model="isFacebookNews[indextr]" @change="setFacebookNews(tr.id, indextr)" style="align: center"/><br>
            <md-checkbox v-model="isTwitterNews[indextr]" @change="setTwitterNews(tr.id, indextr)" style="align: center"/>
          </vs-td>
          <vs-td v-if="windowWidth > 910"><vs-button color="dark" :disabled="tr.is_sendable == 'no'" style="padding: 0.5em;" type="line" @click="sendNewsflash(tr.b_sbj, tr.id)">전송</vs-button></vs-td>
          <vs-td v-else style="text-align: center;">
            <md-checkbox v-model="isHuboNews[indextr]" style="align: center"/><br>
            <!-- <md-checkbox v-model="isProjectNews[indextr]" @change="setProjectNews(isProjectNews[indextr], tr.id)" style="align: center"/> -->
            <div v-if="(activeUserInfo.level >= 10 || activeUserInfo.permission.sns)">
              <md-checkbox v-model="isFacebookNews[indextr]" @change="setFacebookNews(tr.id, indextr)" style="align: center"/><br>
              <md-checkbox v-model="isTwitterNews[indextr]" @change="setTwitterNews(tr.id, indextr)" style="align: center"/>
            </div>
            <vs-button color="dark" :disabled="tr.is_sendable == 'no'" style="padding: 0.5em;" type="line" @click="sendNewsflash(tr.b_sbj, tr.id)">전송</vs-button>
          </vs-td>
          <vs-td>
            <div class="flex">
              <div v-if="activeUserInfo.level > 7 || activeUserInfo.id == news[selectedIndex].mno || (activeUserInfo.permission.departmentEdit && $route.query.department == activeUserInfo.department)" :style="windowWidth > 910 ? 'padding:5px' : 'padding:5px 0px'">
                <vs-button @click="editNews(tr.id)" color="#FC7F03" type="line" size="small" icon-pack="feather" icon="icon-edit"></vs-button>
              </div>
              <div :style="windowWidth > 910 ? 'padding:10px' : 'padding:10px 5px'" @click="showNews(indextr, tr.id)">
                <span v-html="stateColor(tr.state, tr.viewed, tr.out_site, tr.b_sbj)"/><span v-if="tr.image_count > 0"><feather-icon style="margin-left: 5px; vertical-align: middle;" size="17" icon="ImageIcon"></feather-icon>{{tr.image_count}}</span>
              </div>
            </div>
          </vs-td>
          <vs-td><span v-html="stateColor(tr.state, tr.viewed, tr.out_site, stateToText(tr.state))"/></vs-td>
          <vs-td v-if="tr.state == 1 && tr.exist_out_site == 'no'"><div><vs-button type="border" color="#6593F5" icon-pack="feather" icon="icon-send" @click="sendNewsToPortal(tr)"></vs-button></div></vs-td>
          <vs-td v-else-if="tr.state == 8 || (tr.state != 1 && activeUserInfo.level != 10)"><div><vs-button disabled type="border" color="#6593F5" icon-pack="feather" icon="icon-send"></vs-button></div></vs-td>
          <vs-td v-else-if="activeUserInfo.level == 10 && tr.state == 1 && tr.exist_out_site == 'yes'"><div><vs-button type="border" color="#ff0000" icon-pack="feather" icon="icon-trash" @click="tryDeleteNewsPortal(tr)"></vs-button></div></vs-td>
          <vs-td v-else><div><vs-button disabled type="border" color="#ff0000" icon-pack="feather" icon="icon-send"></vs-button></div></vs-td>
          <!-- <vs-td v-if="activeUserInfo.level != 10 && tr.state == 1 && tr.exist_out_site == 'yes'"><div><vs-button disabled type="border" color="#ff0000" icon-pack="feather" icon="icon-trash" @click="tryDeleteNewsPortal(tr)"></vs-button></div></vs-td> -->
          
          <vs-td v-tooltip="{content: '송고시간: ' +tr.d_pass2 + '<br>수정시간: ' + tr.d_modi + '<br>작성시간: ' + tr.d_write, placement: 'top'}">{{ searchVar.sort == "modi"? tr.d_modi : tr.d_pass }}</vs-td>
          <vs-td>{{ tr.writer }}</vs-td>
          <vs-td>{{ tr.menu_code }}</vs-td>
          <vs-td>{{ tr.viewed }}</vs-td>
          <vs-td v-tooltip="{content: tr.out_site, placement: 'top'}"><span v-html="portalText(tr.out_site)"/></vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div>
        <vs-pagination :total="numOfPages" v-model="currentx"></vs-pagination>
    </div>

    <!-- Start: 뉴스 상세보기 팝업 -->
    <div id="div-with-loading" class="news-detail-form vs-con-loading__container">
      <vs-popup ref="popup-newsDetail" classContent="popup-newsDetail" title="기사 상세" :active.sync="newsDetailModalActive">
        <div class="items-center">
          <div class="vx-row mb-6" style="margin:0 auto; position: sticky; top: 0;">
            <div style="margin:0 auto;">
              <vs-button v-if="activeUserInfo.level > 7 || activeUserInfo.id == news[selectedIndex].mno || (activeUserInfo.permission.departmentEdit && $route.query.department == activeUserInfo.department)" vs-type="gradient" color="success" icon="create" @click="editNews(selectedId)">기사수정</vs-button>
            </div>
            <div class="vx-row mb-6" v-if="(activeUserInfo.level > 10 || activeUserInfo.permission.delete || (activeUserInfo.permission.departmentDelete && $route.query.department == activeUserInfo.department))">
              <v-select v-if="newsDetail.state == '1'" placeholder="포털 선택" label="name" v-model="portalSelect"
                multiple :options="$store.state.portalsWithAll" :closeOnSelect="false" style="width: 150px;">
              </v-select>
              <vs-button v-if="newsDetail.state == '1'" vs-type="flat" color="warning" icon="delete_sweep" @click="deletePortalNews(portalSelect, selectedId)">포털삭제</vs-button>
              <vs-button vs-type="flat" color="danger" icon="delete_sweep" @click="deleteNews(selectedId, newsDetail.state)">기사삭제</vs-button>
            </div>
            <!-- <vs-button v-if="activeUserInfo.level == 10 || (activeUserInfo.id == news[selectedIndex].mno && news[selectedIndex].state >= 8)" vs-type="flat" color="danger" icon="delete_sweep" @click="deleteNews(selectedId, newsDetail.state)">기사삭제</vs-button> -->
          </div>
          
          <div class="flex" style="position: sticky; top: 60.5px;flex-direction: row-reverse;">
            <form name="pf">
              <input type=hidden name="printzone">
              <input type=hidden name="title">
              <vs-button vs-type="flat" size="small" @click="print()">인쇄</vs-button>
            </form>
          </div>
          <div class="news-detail">
            <div v-html="newsDetail.data && newsDetail.data.preview ? newsDetail.data.preview : ''"></div>
          </div>
          <div class="flex" style="justify-content: space-around; margin-bottom: 20px; padding: 20px 0; border-bottom: 1px solid #ccc; border-top: 1px solid #ccc;">
            <!-- 미리보기: 
            <vs-radio color="success" v-model="previewType" vs-value="iPhone1">아이폰1(375)</vs-radio>
            <vs-radio color="success" v-model="previewType" vs-value="iPhone2">아이폰2(414)</vs-radio>
            <vs-radio color="success" v-model="previewType" vs-value="android1">안드로이드1(360)</vs-radio>
            <vs-radio color="success" v-model="previewType" vs-value="android2">안드로이드2(420)</vs-radio>
            <vs-radio color="success" v-model="previewType" vs-value="pc">PC</vs-radio> -->
            
            <vs-button color="blue" size="small" @click="previewType = 'iPhone1'" style="width: 25%" >iOS 미리보기</vs-button>
            <vs-button color="danger" size="small" @click="previewType = 'android1'" style="width: 25%" >Android 미리보기</vs-button>
            <vs-button color="grey" size="small" @click="previewType = 'pc'" style="width: 25%" >PC 미리보기</vs-button>
          </div>
          
          <div id="view_con" :class="previewType == 'pc' ? 'news-detail' : 
            previewType == 'iPhone1' ? 'newsDetailMobile iPhone1' : 
            previewType == 'iPhone2' ? 'newsDetailMobile iPhone2' :
            previewType == 'android1' ? 'newsDetailMobile android1' :
            previewType == 'android2' ? 'newsDetailMobile android2' :
            previewType == 'android3' ? 'newsDetailMobile android3' : ''">
            <div v-html="newsDetail.data && newsDetail.data.article ? newsDetail.data.article : newsDetail.data"></div>
          </div>
        </div>
      </vs-popup>
    </div>
    <!-- End: 뉴스 상세보기 팝업 -->

    <!-- Start: 포털 삭제 팝업 -->
    <div class="write-new-ad-form">
        <vs-popup classContent="popup-example" title="삭제 포털 선택" :active.sync="portalDeleteActive">

            <div v-for="(item, idx) in $store.state.portalsWithAll" v-bind:key="idx">
                <md-checkbox style="padding: 20px" v-model="selectedPortalsToDelete[idx]">{{ item.name }}</md-checkbox>
            </div>

            <vs-button class="p-2" style="width: 95%" type="border" color="primary" @click="deleteNewsPortal(); portalDeleteActive = false">선택삭제</vs-button>
            <vs-button class="p-2" style="width: 95%" type="border" color="danger" @click="deleteNewsPortalAll(); portalDeleteActive = false">전체삭제</vs-button>
        </vs-popup>
    </div>
    <!-- End: 포털 삭제 팝업 -->

  </div>
</template>

<script>
import vSelect from 'vue-select'
//you need to import the CSS manually (in case you want to override it)
import moment from 'moment';

export default {
  components: {
    'v-select': vSelect,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      previewType: 'android1',
      selectedPortalsToDelete: [],
      portalDeleteActive: false,
      selectedIndex: 0,
      selectedId: 0,
      news: [{descr: ''}],
      isHuboNews: [],
      isHuboNewsSnapshot: [],
      isProjectNews: [],
      isFacebookNews: [],
      isTwitterNews: [],
      currentx: 1,
      numOfNews: 0,
      numOfPages: 0,
      sentNewsflashCnt: 0,
      searchVar: {
        startRow: 0,
        itemsPerPage: 15,
        state: null,
        category1: null,
        series: null,
        rank: null,
        writer: null,
        searchType: 'title',
        searchText: '',
        startDate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
        endDate: moment().add(2, 'week').format('YYYY-MM-DD'),
        department: '',
        sort: 'pass',
      },
      state: null,
      category1: null,
      series: null,
      rank: null,
      writer_s: null,
      searchType: {name: '제목', code: 'title'},
      searchText: '',
      dateRange: { // used for v-model prop
          startDate: moment().subtract(1, 'month'),
          endDate: moment().add(2, 'week'),
      },
      dateRange2: [new Date(moment().subtract(1, 'month')), new Date(moment().add(2, 'week'))],
      newsDetail: '',
      writer: [],
      types: [],
      states: [],
      categories1: [],
      categories1_ok: false,
      categories2: [],
      categories2_view: [],
      seriesArray: [],
      category2Disable: true,
      portals: [],
      portalSelect: [],
      isMounted: false,
      addNewDataSidebar: false,
      newsDetailModalActive: false,
      ranges: { //default value for ranges object (if you set this to false ranges will no be rendered)
          '오늘': [moment(), moment()],
          '1주일': [moment().subtract(1, 'week'), moment()],
          '한달': [moment().subtract(1, 'month'), moment()],
          '이번 달': [moment().startOf('month'), moment().endOf('month')],
          '지난 달': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      },
      opens: "center",//which way the picker opens, default "center", can be "left"/"right"
      locale: {
          direction: 'ltr', //direction of text
          format: 'YYYY-MM-DD', //fomart of the dates displayed
          separator: ' - ', //separator between the two ranges
          applyLabel: '선택',
          cancelLabel: '취소',
          weekLabel: 'W',
          customRangeLabel: 'Custom Range',
          "daysOfWeek": [
              "일",
              "월",
              "화",
              "수",
              "목",
              "금",
              "토"
          ],
          "monthNames": [
              "1월",
              "2월",
              "3월",
              "4월",
              "5월",
              "6월",
              "7월",
              "8월",
              "9월",
              "10월",
              "11월",
              "12월"
          ],
          firstDay: 0, //ISO first day of week - see moment documenations for details
          showISOWeekNumbers: true //show week numbers on each row of the calendar
      },
    }
  },
  beforeRouteLeave (to, from, next) {
    for(const popup of document.querySelectorAll('.con-vs-popup.vs-popup-primary')){
      popup.remove();
    }
    this.newsDetailModalActive = false;
    setTimeout(() => { next(); }, 100);
  },
  watch: {
    isHuboNews: function() {
      console.log('hubo', this.isHuboNews, this.isHuboNewsSnapshot)
      var thisIns = this

      for(var idx in this.isHuboNews) {
        
        if(this.isHuboNewsSnapshot[idx] != this.isHuboNews[idx]) {
          this.$http.post('/api/updateHuboNews', {id: this.news[idx].id, register: this.isHuboNews[idx]})
            .then(function (response) {
              console.log(response.data);
              thisIns.$vs.notify({
                title:'성공',
                text: '후보지정 완료',
                color:'success',
                iconPack: 'feather',
                icon:'icon-alert-circle'
              })
            })
            .catch(function (error) {
              thisIns.$vs.notify({
                title:'Error',
                text: error,
                color:'danger',
                iconPack: 'feather',
                icon:'icon-alert-circle'})
              thisIns.$vs.loading.close();  
            });
        }
        
        this.isHuboNewsSnapshot[idx] = this.isHuboNews[idx]
      }
    },
    '$route.query': function() {
      console.log(this.$route.query);
      this.newsDetailModalActive = false;
      if(this.$route.query.startRow) this.searchVar.startRow = parseInt(this.$route.query.startRow);
      else {
        this.searchVar.startRow = 0;
        this.currentx = 1;
        if(this.$route.query.startRow != 0){
          this.state= null;
          this.category1= null;
          this.series= null;
          this.rank= null;
          this.writer_s= null;
          this.searchText= '';
          this.dateRange= { // used for v-model prop
              startDate: moment().subtract(2, 'week'),
              endDate: moment().add(2, 'week'),
          }
        }
      }
      if(this.$route.query.state || this.$route.query.state == 0) this.searchVar.state = this.$route.query.state;
      else this.searchVar.state = null;
      if(this.$route.query.category1) this.searchVar.category1 = this.$route.query.category1;
      else this.searchVar.category1 = null;
      if(this.$route.query.series) this.searchVar.series = this.$route.query.series;
      else this.searchVar.series = null;
      if(this.$route.query.rank) this.searchVar.rank = this.$route.query.rank;
      else this.searchVar.rank = null;
      if(this.$route.query.writer) this.searchVar.writer = this.$route.query.writer;
      else this.searchVar.writer = null;
      if(this.$route.query.searchType) this.searchVar.searchType = this.$route.query.searchType;
      else this.$route.query.searchType = '';
      if(this.$route.query.searchText) this.searchVar.searchText = this.$route.query.searchText;
      else this.searchVar.searchText = '';
      if(this.$route.query.startDate) this.searchVar.startDate = this.$route.query.startDate;
      else this.searchVar.startDate = moment().subtract(1, 'month').format('YYYY-MM-DD');
      if(this.$route.query.endDate) this.searchVar.endDate = this.$route.query.endDate;
      else this.searchVar.endDate = moment().add(2, 'week').format('YYYY-MM-DD');
      if(this.$route.query.department) this.searchVar.department = this.$route.query.department;
      if(this.$route.query.sort) this.searchVar.sort = this.$route.query.sort;
      // else this.searchVar.department = this.activeUserInfo.department;
      this.getNewsList(this.searchVar);
    },
    currentx: function() {
                                    // currentx-1 is value before changed by watch: -> currentx:
      this.searchVar.startRow = (this.currentx-1) * this.searchVar.itemsPerPage;
      window.scrollTo(0,0);
      console.log(this.searchVar)
      this.$router.push({path: this.$route.path, query: this.searchVar})
      // this.getNewsList(this.searchVar);
    }
  },
  computed: {
    currentPage() {
      if(this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    // PROFILE
    activeUserInfo() {
        return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    print(){
      console.log(document.pf)
      document.pf.printzone.value = document.querySelector('#view_con').innerHTML;
      document.pf.title.value = document.querySelector('#view_con .title').innerText;
      window.open("/api/printPage.html", "print_open","width=800,height=700,top=0,left=0,noresizable,toolbar=no,status=no,scrollbars=yes,directory=no");
    },
    portalText(data){
      // console.log(data)
      let portalText;
      let sendFlg = 0;
      if(data){
        data.split("|").forEach(sended =>{
          if(this.$store.state.portals.find(e => e.name.toLowerCase() == sended.toLowerCase() || e.oldName == sended)){
            sendFlg++;
          }
        })
        if(sendFlg >= this.$store.state.portals.length) {
          portalText = "완";
        }else if(sendFlg > 0){
          portalText = "미완";
        }else{
          portalText = "";
        }
      }else{
        portalText = "";
      }
      return(portalText);
    },
    stateToText(state){
      let stateText;
      switch(state)
      {
        case '*': stateText ="상태"; break;
        case '0': stateText ="보류"; break;
        case '1': stateText ="송료"; break;
        case '2': stateText ="송예"; break;
        case '3': stateText ="편료"; break;
        case '4': stateText ="편중"; break;
        case '8': stateText ="작료"; break;
        case '9': stateText ="작중"; break;
      }
      return(stateText);
    },
    stateColor(state, viewed, outSite, text){
      let stateText;
      switch(state)
      {
        case '0': 
          if(viewed > 0){
            stateText ="<font color='#2244DD'>" + text.replace(/<\/?(span[^>]*|u[^>]*|em[^>]*|strong[^>]*|p[^>]*)>/g, '') + "</font>";
          }else{
            stateText ="<font color='black'>" + text.replace(/<\/?(span[^>]*|u[^>]*|em[^>]*|strong[^>]*|p[^>]*)>/g, '') + "</font>"; 
          }
          break;
        case '1':
          var sendFlg = 0;
          var flashFlg = 0;
          if(outSite){
            outSite.split("|").forEach(sended =>{
              let sended2 = sended.trim();
              if(this.$store.state.portals.find(e => e.name.toLowerCase() == sended2.toLowerCase() || e.oldName == sended2)){
                sendFlg++;
              }
              if(sended2.toLowerCase() == 'NaverFlash'.toLowerCase()){
                flashFlg = 1;
              }
            })
          }
          
          if(flashFlg > 0 && text != '송료'){
             stateText = "<font color='#FF1235'>[속보]</font><font color='blue'>" + text.replace(/<\/?(span[^>]*|u[^>]*|em[^>]*|strong[^>]*|p[^>]*)>/g, '').replace(/(^\[?속보\]?)/, '') + "</font>"; 
          }else if(sendFlg < this.$store.state.portals.length && sendFlg > 0) {
             stateText = "<font color='#CC5555'>" + text.replace(/<\/?(span[^>]*|u[^>]*|em[^>]*|strong[^>]*|p[^>]*)>/g, '') + "</font>"; 
          }else{
            stateText = "<font color='blue'>" + text.replace(/<\/?(span[^>]*|u[^>]*|em[^>]*|strong[^>]*|p[^>]*)>/g, '') + "</font>"; 
          }
         
          break;
        case '2': stateText ="<font color='#008844'>" + text.replace(/<\/?(span[^>]*|u[^>]*|em[^>]*|strong[^>]*|p[^>]*)>/g, '') + "</font>"; break;
        case '3': stateText ="<font color='red'>" + text.replace(/<\/?(span[^>]*|u[^>]*|em[^>]*|strong[^>]*|p[^>]*)>/g, '') + "</font>"; break;
        case '4': stateText ="<font color='#666600'>" + text.replace(/<\/?(span[^>]*|u[^>]*|em[^>]*|strong[^>]*|p[^>]*)>/g, '') + "</font>"; break;
        case '8': stateText ="<font color='#666600'>" + text.replace(/<\/?(span[^>]*|u[^>]*|em[^>]*|strong[^>]*|p[^>]*)>/g, '') + "</font>"; break;
        case '9': stateText ="<font color='darkviolet'>" + text.replace(/<\/?(span[^>]*|u[^>]*|em[^>]*|strong[^>]*|p[^>]*)>/g, '') + "</font>"; break;
      }
      return(stateText);
    },
    eventColor(evnetFlg, text){
      let result = text;
      
      switch(evnetFlg)
      {
        case 1:
          result = "<font color='red'>" + text + "</font>";
          break;
        default:
          result = text
          break;
      }
      return result;
    },
    changeSort(type){
      if(this.searchVar.sort != type){
        this.searchVar.sort = type;
        this.$router.push({path: this.$route.path, query: this.searchVar})
      }
    },
    setFacebookNews(newsId, indextr){
      const thisIns = this;
      let news = this.news.find(e => e.id == newsId) || {};
      console.log('fb: ', newsId, news, indextr);
      let getText = "";
      let confirmText = `${newsId}번 기사를 송고 시 페이스북으로 전송하겠습니까?\n아래에 문구를 입력해주세요.\n줄바꿈 하고싶으면 ; 를 입력하세요.`;
      if(thisIns.isFacebookNews[indextr]){
        if(news.state == 1){
          confirmText = confirmText.replace('송고 시 ', '');
        }
        getText = prompt(confirmText);
        if(getText == "null"){
          getText = null;
        }
        console.log(getText)
      }else{
        if(news.state == 1){
          confirmText = `${newsId}번 기사를 페이스북에서 삭제하겠습니까?`;
        }else{
          confirmText = `${newsId}번 기사를 페이스북 전송 리스트에서 삭제하겠습니까?`;
        }
        if(!confirm(confirmText)){
          getText = null;
        }
      }
      if(getText || getText == ""){
        // this.$vs.loading({
        //   scale: 1.5
        // });
        getText = getText.replace(/;/g, '\n');
        this.$http.post('/api/writeArticles/updateSns', {
            id: newsId, 
            register: thisIns.isFacebookNews[indextr], 
            sns: 'facebook',
            text: getText,
          })
          .then(function (response) {
            console.log(typeof response.data);
            thisIns.$vs.loading.close();  
            if(typeof response.data == 'object'){
              thisIns.$vs.notify({
                title:'성공',
                text: 'SNS지정 완료',
                color:'success',
                iconPack: 'feather',
                icon:'icon-alert-circle'
              })
            }else{
              throw Error('api fail')
            }
          })
          .catch(function (error) {
            thisIns.isFacebookNews[indextr] = !thisIns.isFacebookNews[indextr];
            thisIns.isFacebookNews = JSON.parse(JSON.stringify(thisIns.isFacebookNews));
            thisIns.$vs.notify({
              title:'Error',
              text: error,
              color:'danger',
              iconPack: 'feather',
              icon:'icon-alert-circle'})
            thisIns.$vs.loading.close();  
          });
      }else{
        thisIns.isFacebookNews[indextr] = !thisIns.isFacebookNews[indextr];
        thisIns.isFacebookNews = JSON.parse(JSON.stringify(thisIns.isFacebookNews));
      }
    },
    setTwitterNews(newsId, indextr){
      const thisIns = this;
      let news = this.news.find(e => e.id == newsId) || {};
      console.log('tw: ', newsId, news, thisIns.isTwitterNews[indextr]);
      let getText = "";
      let confirmText = `${newsId}번 기사를 송고 시 트위터로 전송하겠습니까?\n아래에 문구를 입력해주세요.\n줄바꿈 하고싶으면 ; 를 입력하세요.`;
      if(thisIns.isTwitterNews[indextr]){
        if(news.state == 1){
          confirmText = confirmText.replace('송고 시 ', '');
        }
        getText = prompt(confirmText);
        if(getText == "null"){
          getText = null;
        }
        // console.log(getText)
      }else{
        if(news.state == 1){
          confirmText = `${newsId}번 기사를 트위터에서 삭제하겠습니까?`;
        }else{
          confirmText = `${newsId}번 기사를 트위터 전송 리스트에서 삭제하겠습니까?`;
        }
        if(!confirm(confirmText)){
          getText = null;
        }
      }
      if(getText || getText == ""){
        // this.$vs.loading({
        //   scale: 1.5
        // });
        getText = getText.replace(/;/g, '\n');
        this.$http.post('/api/writeArticles/updateSns', {
            id: newsId, 
            register: thisIns.isTwitterNews[indextr], 
            sns: 'twitter',
            text: getText,
          })
          .then(function (response) {
            console.log(typeof response.data);
            thisIns.$vs.loading.close();  
            if(typeof response.data == 'object'){
              thisIns.$vs.notify({
                title:'성공',
                text: 'SNS지정 완료',
                color:'success',
                iconPack: 'feather',
                icon:'icon-alert-circle'
              })
            }else{
              throw Error('api fail')
            }
          })
          .catch(function (error) {
            thisIns.isTwitterNews[indextr] = !thisIns.isTwitterNews[indextr];
            thisIns.isTwitterNews = JSON.parse(JSON.stringify(thisIns.isTwitterNews));
            thisIns.$vs.notify({
              title:'Error',
              text: error,
              color:'danger',
              iconPack: 'feather',
              icon:'icon-alert-circle'})
            thisIns.$vs.loading.close();  
          });
      }else{
        thisIns.isTwitterNews[indextr] = !thisIns.isTwitterNews[indextr];
        thisIns.isTwitterNews = JSON.parse(JSON.stringify(thisIns.isTwitterNews));
      }
    },
    setProjectNews(event, newsId){
      console.log('project: ', event, newsId)
      const thisIns = this;
      this.$http.post('/api/updateProjectNews', {id: newsId, register: event})
        .then(function (response) {
          console.log(response.data);
          thisIns.$vs.notify({
            title:'성공',
            text: '기획지정 완료',
            color:'success',
            iconPack: 'feather',
            icon:'icon-alert-circle'
          })
        })
        .catch(function (error) {
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    showNews(index, newsId){
      this.newsDetailModalActive = true;
      this.selectedIndex = index;
      this.selectedId = newsId;
      console.log(this.selectedIndex, this.selectedId);
      this.newsDetail = '';
      const thisIns = this;
      this.$vs.loading({
        scale: 1.5
      });
      this.$http.post('/api/getSingleNews/detail', {id: newsId})
        .then(function (response) {
          console.log(response.data);
          thisIns.newsDetail = response.data;
          thisIns.$vs.loading.close();
          // var images = response.data[1];
          // for(var i=0;i<images.length;i++){
          //   thisIns.sendData.arrayBase64.push(images[i].img);
          //   thisIns.sendData.arrayImageText.push(images[i].img_c);
          //   thisIns.sendData.arrayImageAlign.push(images[i].align);
          //   thisIns.sendData.arrayImageWidth.push(images[i].width);
          // }
          // var tags = response.data[2];
          // for(var i=0;i<tags.length;i++){
          //   thisIns.sendData.tag.push(tags[i].tag);
          // }
        })
        .catch(function (error) {
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    editNews(newsId){
      console.log("edit: " + newsId);
      this.newsDetailModalActive = false;
      let pathNow = this.$route.path;
      let pathNew = pathNow.replace('newsListAll', 'writeArticles');
      // console.log('Path: ', pathNow, pathNew)
      this.$router.push({path: pathNew, query: { id: newsId}});
    },
    tryDeleteNewsPortal(item) {
      
      this.itemToDelPortal = item
      this.selectedPortalsToDelete = []
      this.portalDeleteActive = true
    },
    deleteNewsPortal() {

      var selectedPortalsToDel = []
      for(var idx in this.selectedPortalsToDelete) {

        if(this.selectedPortalsToDelete[idx]) {
          selectedPortalsToDel.push(this.$store.state.portalsWithAll[idx])
        }
      }
      if(selectedPortalsToDel.find(e => e.name == 'ALL')){
        selectedPortalsToDel = []
        for(idx in this.$store.state.portalsWithAll) {
            selectedPortalsToDel.push(this.$store.state.portalsWithAll[idx])
        }
      }

      var thisIns = this

      var newsTitle = this.itemToDelPortal.b_sbj
      console.log(selectedPortalsToDel)
      // var newsTitle = 'hello'
      thisIns.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `포털 선택 삭제`,
        text: `'${newsTitle}'를(을) 포털 삭제 하시겠습니까?`,
        accept: function() {
          
          thisIns.deletePortalNews(selectedPortalsToDel, thisIns.itemToDelPortal.id)
        }
      })
    },
    deleteNewsPortalAll() {

      var selectedPortalsToDel = []

      for(var idx in this.$store.state.portalsWithAll) {

          selectedPortalsToDel.push(this.$store.state.portalsWithAll[idx])
      }

      var thisIns = this

      var newsTitle = this.itemToDelPortal.b_sbj
      console.log(selectedPortalsToDel)

      // var newsTitle = 'hello2'
      thisIns.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `포털 전체 삭제`,
        text: `'${newsTitle}'를(을) 포털 삭제 하시겠습니까?`,
        accept: function() {
          
          thisIns.deletePortalNews(selectedPortalsToDel, thisIns.itemToDelPortal.id)
        }
      })
    },
    sendNewsflash(newsTitle, newsId) {
      var thisIns = this
      
      console.log('newsId:', newsId)
      let limitCnt = 5;
      if(moment() > moment('2024-04-10') && moment() < moment('2024-04-12')){
        limitCnt = 15;
      }
      thisIns.$vs.dialog({
        type: 'confirm',
        color: 'primary',
        title: `속보 전송 (${limitCnt - thisIns.sentNewsflashCnt >= 0 ? limitCnt - thisIns.sentNewsflashCnt : 0}회 남음)`,
        text: `'${newsTitle}'를(을) 속보로 보내시겠습니까?`,
        accept: function() {

          thisIns.$http.post('/api/sendNewsflash', {newsId: newsId})
            .then(function (response) {

              console.log(response)

              if(response.data.is_sent) {

                thisIns.sentNewsflashCnt++

                thisIns.$vs.notify({
                  title:'속보 전송 완료',
                  text: `'${newsTitle}'`,
                  color:'success',
                  iconPack: 'feather',
                  icon:'icon-alert-circle'})
              }
              else {

                if(response.data.result == 'send_limit_exceed') {

                  thisIns.$vs.notify({
                    title:'속보 전송 실패',
                    text: `금일 전송 한도를 초과하였습니다.`,
                    color:'danger',
                    iconPack: 'feather',
                    icon:'icon-alert-circle'})
                }
                else if(response.data.result == 'portal_upload_failed') {

                  thisIns.$vs.notify({
                    title:'속보 전송 실패',
                    text: `속보 전송 중 오류가 발생했습니다.`,
                    color:'danger',
                    iconPack: 'feather',
                    icon:'icon-alert-circle'})
                }
                else {

                  thisIns.$vs.notify({
                    title:'속보 전송 실패',
                    text: `알 수 없는 오류로 인해 전송에 실패했습니다.`,
                    color:'danger',
                    iconPack: 'feather',
                    icon:'icon-alert-circle'})
                }
              }
            })
            .catch(function (error) {
              
                thisIns.$vs.notify({
                  title:'Error',
                  text: error,
                  color:'danger',
                  iconPack: 'feather',
                  icon:'icon-alert-circle'})
                thisIns.$vs.loading.close();  
            });
        }
      })
    },
    sendNewsToPortal(item) {

      var thisIns = this

      var newsId = item.id
      var newsTitle = item.b_sbj
      
      console.log('newsId:', newsId)

      thisIns.$vs.dialog({
        type: 'confirm',
        color: '#6593F5',
        title: `포털 송고`,
        text: `'${newsTitle}'를(을) 포털로 송고하시겠습니까?`,
        accept: function() {

          this.$http.post('/api/quickPortalSend', {newsId: newsId})
          .then(function (res) {

            console.log('res)', res.data)

            if(res.data.status == 'success') {

              item.state = '1'
              thisIns.getNewsList(thisIns.searchVar);

              thisIns.$vs.notify({
                title:'포털 송고 완료',
                text: `'${newsTitle}'`,
                color:'success',
                iconPack: 'feather',
                icon:'icon-alert-circle'})
            }
            else {

              thisIns.$vs.notify({
                title: `오류`,
                text: `알 수 없는 오류로 인해 포털 송고에 실패했습니다`,
                color:'danger',
                iconPack: 'feather',
                icon:'icon-alert-circle'})
            }
          })
          .catch(function (error) {
            thisIns.$vs.notify({
              title:'Error',
              text: error,
              color:'danger',
              iconPack: 'feather',
              icon:'icon-alert-circle'})
            thisIns.$vs.loading.close();
          });
        }
      })
    },
    
    deletePortalNews(selectedPortal, newsId){
      if(selectedPortal.find(e => e.name == 'ALL')){
        selectedPortal = []
        for(const portal of this.portals) {
            selectedPortal.push(portal)
        }
      }
      for(let item of selectedPortal){
        item['action'] = "D";
      }
      console.log("delete: " + newsId, selectedPortal);
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      this.$http.post('/api/writeArticles/portalDelete', {'id': newsId, 'portalAction': selectedPortal})
        .then(function (res) {
          thisIns.$vs.notify({
            title:'성공',
            text: '포털 삭제 성공',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          console.log(res);
          thisIns.newsDetailModalActive = false;
          thisIns.getNewsList(thisIns.searchVar);
          thisIns.$vs.loading.close();  
        })
        .catch(function (error) {
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();
        });
    },
    deleteNews(newsId, state){
      if(confirm(`${newsId}번 기사를 삭제하시겠습니까?`)){
        console.log("delete: " + newsId, state);
        this.$vs.loading({
          scale: 1.5
        });
        const thisIns = this;
        let delFlg = false;
        if(state == 1){ delFlg = true }
        this.$http.post('/api/writeArticles/delete', { id: newsId, delFlg })
          .then(function (res) {
            thisIns.$vs.notify({
              title:'성공',
              text: '기사 삭제 성공',
              color:'primary',
              iconPack: 'feather',
              icon:'icon-alert-circle'})
              console.log(res);
              thisIns.newsDetailModalActive = false;
              thisIns.getNewsList(thisIns.searchVar);
              thisIns.$vs.loading.close();  
          })
          .catch(function (error) {
            var errorText = error.toString();
            if(errorText.includes('401')){
              alert('로그인 해주세요!')
              thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
            }
            thisIns.$vs.notify({
              title:'Error',
              text: error,
              color:'danger',
              iconPack: 'feather',
              icon:'icon-alert-circle'})
            thisIns.$vs.loading.close();
          });
      }
    },
    changeItemPerPage(pageNum) {
      this.renderMethod.itemsPerPage = pageNum
      // this.getNewsList(this.searchVar)
    },
    searchNewsList(data){
      data.startRow = 0;
      // console.log(this.state)
      data.state = this.state;
      data.category1 = this.category1;
      if(data.category1){
        this.$route.query.department = '';
        this.searchVar.department = '';
      }
      data.series = this.series;
      data.rank = this.rank;
      data.writer = this.writer_s;
      data.searchText = this.searchText;
      data.searchType = this.searchType.code;
      data.startDate = moment(this.dateRange2[0]).format('YYYY-MM-DD');
      data.endDate = moment(this.dateRange2[1]).format('YYYY-MM-DD');
      data.department = this.searchVar.department;
      this.$router.push({path: this.$route.path, query: data})
      this.currentx = 1;
      // this.getNewsList(data);
    },
    getNewsList(data) {
      console.log(data.state, data.category1, data.writer, data.series)

      data.level = this.activeUserInfo.level
      data.id = this.activeUserInfo.id
      
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      this.$http.post('/api/getNewsList', data)
        .then(function (response) {
          console.log(response);
          if(response.data[0][0].news_count){
            thisIns.numOfNews = response.data[0][0].news_count;
            thisIns.numOfPages = Math.ceil(response.data[0][0].news_count / thisIns.searchVar.itemsPerPage);
            thisIns.news = response.data[1];
            thisIns.sentNewsflashCnt = response.data[2][0].cnt

            for(var idx in thisIns.news) {
              if(thisIns.news[idx].sns){
                thisIns.news[idx].sns = JSON.parse(thisIns.news[idx].sns);
              }else{
                thisIns.news[idx].sns = {};
              }
              thisIns.isFacebookNews[idx] = thisIns.news[idx].sns.facebookFlg || thisIns.news[idx].sns.facebookId ? true : false
              thisIns.isTwitterNews[idx] = thisIns.news[idx].sns.twitterFlg || thisIns.news[idx].sns.twitterId ? true : false
              thisIns.isProjectNews[idx] = thisIns.news[idx].isProject ? true : false
              thisIns.isHuboNews[idx] = thisIns.news[idx].is_hubo ? true : false
              thisIns.isHuboNewsSnapshot[idx] = thisIns.news[idx].is_hubo ? true : false
            }
            // thisIns.isFacebookNews = JSON.parse(JSON.stringify(thisIns.isFacebookNews));
            // thisIns.isTwitterNews = JSON.parse(JSON.stringify(thisIns.isTwitterNews));
            console.log(thisIns.isFacebookNews);

            // for(var i=0; i< thisIns.news.length;i++){
            //   // console.log(response.data[2][i].length);
            //   thisIns.news[i]['menu_code'] = "";
            //   if(thisIns.news.length > 1){
            //     for(var j=0;j<response.data[2][i].length;j++){
            //       thisIns.news[i]['menu_code'] += response.data[2][i][j].name + "| "
            //     }
            //   }else{
            //     for(var j=0;j<response.data[2].length;j++){
            //       thisIns.news[i]['menu_code'] += response.data[2][j].name + "| "
            //     }
            //   }
            //   thisIns.news[i]['menu_code'] = thisIns.news[i]['menu_code'].substr(0,thisIns.news[i]['menu_code'].length-1);
            //   // if(data.category1){
            //   //   data.menu_code = '';
            //   //   data.category1.split(';').forEach(function (data, category) {
            //   //     if(category){
            //   //       // console.log('category: ', category, data);
            //   //       data.menu_code += thisIns.categories1.find(_category => _category.code == category).name + '|';
            //   //     }
            //   //   }.bind(null, data))
            //   //   data.menu_code = data.menu_code.slice(0,-1)
            //   // }
            // }
          }else{
            thisIns.numOfNews = 0;
            thisIns.numOfPages = 0;
            thisIns.news = [{descr: ''}];
          }
          thisIns.$vs.loading.close();          
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close(); 
        });
    },
    getOrderStatusColor(status) {
      if(status == 'on hold') return "warning"
      if(status == 'delivered') return "success"
      if(status == 'canceled') return "danger"
      return "primary"
    },
    getPopularityColor(num) {
      if(num > 90) return "success"
      if(num >70) return "primary"
      if(num >= 50) return "warning"
      if(num < 50) return "danger"
      return "primary"
    },
    showSidebar() {
        this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
  },
  created() {
    this.categories1 = [{code: '', name: '전체'}].concat(this.$store.state.categories1);
    console.log(this.categories1)
    this.states = this.$store.state.states;
    this.writer = this.$store.state.writer;
    this.seriesArray = this.$store.state.series;
    this.portals = this.$store.state.portals;
    // console.log(this.$store.state.portals);
    const tmpPage = parseInt(this.$route.query.startRow / this.$route.query.itemsPerPage + 1);
    if(tmpPage > 1) this.currentx = tmpPage;
    this.searchVar.department = '';
    if(this.$route.query.startRow) this.searchVar.startRow = parseInt(this.$route.query.startRow);
    if(this.$route.query.state || this.$route.query.state == 0) this.searchVar.state = this.$route.query.state;
    if(this.$route.query.category1) this.searchVar.category1 = this.$route.query.category1;
    if(this.$route.query.series) this.searchVar.series = this.$route.query.series;
    if(this.$route.query.rank) this.searchVar.rank = this.$route.query.rank;
    if(this.$route.query.writer) this.searchVar.writer = this.$route.query.writer;
    if(this.$route.query.searchType) this.searchVar.searchType = this.$route.query.searchType;
    if(this.$route.query.searchText) this.searchVar.searchText = this.$route.query.searchText;
    if(this.$route.query.startDate) this.searchVar.startDate = this.$route.query.startDate;
    if(this.$route.query.endDate) this.searchVar.endDate = this.$route.query.endDate;
    if(this.$route.query.department) this.searchVar.department = this.$route.query.department;
    // console.log('department: ', this.activeUserInfo.department);
    this.getNewsList(this.searchVar);
  },
  mounted() {
    this.isMounted = true;
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/custom-style/vs-table-newslist.scss";
</style>
